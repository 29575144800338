.container {
  @apply mb-[24px] items-center rounded-xl border
   overflow-clip border-dark-default-1 bg-dark-gray  p-2 sm:p-6;
  box-shadow: 0px 4px 30px rgba(118, 84, 255, 0.1);
}

.settingsSectionSubContainer {
  @apply mb-4 rounded-xl border;
}

.sectionTitle {
  @apply mt-6 block text-lg font-bold text-white;
}

.sectionSubtitle {
  @apply my-[16px] flex items-center text-[15px] text-light-gray sm:w-[80%];
}
