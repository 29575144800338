.menuContent {
  width: 100%;
  height: 100vh;
  @apply bg-gradient-to-b from-dark-default to-primary flex flex-col justify-start 
  shadow-xl shadow-black pt-28;

  position: relative;
  overflow-y: auto;
}

// .menuBackdrop {
//   width: 13%;
//   height: 100vh;
//   cursor: pointer;
//   @apply bg-black/50 backdrop-blur-xl;
// }

.iconsGrid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  padding: 0 2rem;
  margin-top: 2.4rem;
  @apply gap-x-4 gap-y-6;
}

.iosIcon {
  width: 80px;
  height: 80px;
  @apply rounded-[20px] flex items-center justify-center bg-gradient-to-br from-dark-default-1 to-primary border border-dark-pri/70;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  svg {
    color: #fff;
  }
}

.contact {
  @apply flex w-full mx-auto justify-center items-center gap-x-[28px] text-white;
  div {
    @apply uppercase;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
